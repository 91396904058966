<template>
	<div class="box" :class="className">
		<div class="box__title">
			<div @click="$emit('toggleIcon')" class="box__title__icon">
				<slot name="title">
					<h2>Deafult Title</h2>
				</slot>		
			</div>
			<img id="gray-bars" src="../assets/img/gray-bars.png" alt="">
			<slot name="saldo"></slot>
		</div>
		<div class="box__content">
			
			<slot name="subtitle">

			</slot>				
			
			<div class="box__content__body">
				<slot name="body">
					Default body
				</slot>
			</div>

		</div>
	</div>
</template>

<script>
	export default {
		name: 'DialogBox',
		computed: {
		
			className() {
				return this.$route.name.toLowerCase() + '-dialog-box'
			},
		}
	}
</script>

<style lang="scss" scoped>

	.box {
		@include border-radius(0 0 70px 70px);
		background-color: $gray;
		margin: 41.4% auto 0;
		width: 93%;
		z-index: 4;
		padding-bottom: 22px;

		&__title {
			@include flex-container;
			@extend %bold;
			padding: 35px 57px 29px;				
			background-color: $darkGray;
			color: $white;
			
			&__icon {
				cursor: pointer;
			}
			
			h2 {
				font-size: 55px;									
			}

			img {
				margin-left: 23px;
			}
		}

		&__content {				
			color: $darkGray;

			p {
				@extend %italic;
				padding: 50px 136px 0 62px;
				font-size: 36px;
				width: 100%;
			}

			&__body {
				@include border-radius(100px);
				background-image: url('../assets/img/dialog-border-top.png'), url('../assets/img/dialog-border-bottom.png');
				background-repeat: no-repeat, no-repeat;
				background-size: contain, contain;
				background-position: left top, left bottom;
				width: 100%;
				height: 1095px;
				margin-top: 58px;
				padding-top: 166px;
				padding-left: 44px;

				&__subtitle {
					@include flex-container(flex-end, center, center);
					background-image: url('../assets/img/mission-bg.png');
					background-repeat: no-repeat;
					height: 146px;
					width: 494px;
					padding-right: 42px;

					h3 {
						@extend %bold;  
						font-size: 50px;
						line-height: 1.6;
					}


					&.vision {
						background-image: url('../assets/img/vision-bg.png');
						margin-left: 5px;
						
					}
				}
				
				p {
					padding: 31px 60px 0 17px;
					margin-bottom: 45px;
				}
			}
		}

		&.contact-dialog-box, &.rule-dialog-box {

			.box__title {
				padding: 0;
				height: 120px;

				&__icon {
					@include flex-container(flex-end);
					background-image: url('../assets/img/contact-title.png');
					background-repeat: no-repeat;
					background-size: contain;
					position: absolute;
					top: -84px;
					left: -67px;
					z-index: 1;
					height: 278px;
					width: 1096px;
					padding-top: 110px;
					padding-right: 100px;
				}

				h2 {
					color: $black;
					letter-spacing: -2.3px;
				}
			}

			.box__content {

				&__body {
					margin-top: 33px;
					padding-top: 145px;
				}
			}
		}

		&.contact-dialog-box {

			.box__title {

				#gray-bars {
					display: none;
				}				
			}
		}

		&.rule-dialog-box {

			.box__title {
				@include flex-container(flex-end, center, center);

				&__icon {

					background-image: url('../assets/img/rules-title.png');
					top: 27px;
    				left: -65px;					
					padding-top: 62px;
					padding-right: 87px;
					width: 1150px;
				}

				h2 {
					font-size: 68px;
				}

				#gray-bars {
					margin-right: 20.7%;
				}
			}

			.box__content {

				&__body {					
					margin-top: 20px;
					padding: 178px 20px;
					height: 100%;

					ul {

						li {
							@extend %italic;
							font-size: 38px;
							letter-spacing: -.9px;
							text-align: center;
							margin-bottom: 38px;

							b {
								@extend %bold;
							}

							a {
								@extend %italic;
								font-size: 38px;
								color: $darkGray;

								@include nomobile {

									&:hover {
										color: $orangeTheme;
									}
								}
							}
						}
					}
					
					h3 {
						@extend %bold;
						text-align: center;
						font-size: 55px;
						margin: 88px auto;
					}
				}
			}
		}
		
		&.help-dialog-box {
			margin-top: 9%;
			margin-bottom: 5%;
			background-color: $darkGray;
			width: 97%;

			.box__title {
				padding: 25px 57px;

				&__icon {
					margin: 0 auto;
				}

				h2 {
					text-align: center;
					text-transform: uppercase;
				}
				
				#gray-bars {
					display: none;
				}
			}

			.box__content {

				&__body {
					background-image: url('../assets/img/help-border-top.png'), url('../assets/img/help-border-bottom.png');
					background-position: left top, left bottom;
					background-size: contain, contain;
					margin-top: 0;
				}
			}
		}

		&.playground-dialog-box {
			margin-top: -38%;

			.box__title {
				@include flex-container(space-evenly, center, center);
				padding: 0;
				height: 86px;

				h2 {
					@include effect(all, .3s, ease-out);
					color: $black;
					letter-spacing: -2.3px;
				}				

				&__icon {
					@include flex-container;
					position: absolute;
					top: -12px;
					left: -36px;
					z-index: 1;

					&:hover {

						h2 {
							color: $orangeTheme;
						}
					}
				}

				h3 {
					color: $gray;
					font-size: 50px;
					margin-left: 27%;

					&.big {
						font-size: 30px;
					}
				}

				#gray-bars {
					margin-left: 37%;
				}
			}
			
			.box__content {

				&__body {
					margin-top: 43px;
					padding-top: 80px;
					height: 100%;
					min-height: 500px;
				}
			}
		}

		&.history-dialog-box {	
			margin-top: -800px;

			.box__content {

				&__body {
					height: 100%;
					padding-top: 60px;
					padding-bottom: 40px;
					margin-top: 20px;
					min-height: 400px;

					p {
						padding: 0;
						margin-bottom: 0;
					}
				}
			}
		}
	}

	@include desktopMax {

		.box {

			&__content {

				&__body {
					height: 130vh;
				}
			}

			&.contact-dialog-box {

				.box__title {

					&__icon {
						width: 60%;
						padding-top: 80px;
						padding-right: 65px;						
					}
					
					h2 {
						font-size: 2.865vw;
					}
				}
			}
		}
	}

	@include BigDesktop {

		.box {

			&.playground-dialog-box {

				.box__title {
					height: 60px;

					#gray-bars {
						margin-left: 38%;
					}

					h3 {
						margin-left: 80%;
						margin-bottom: 50px;
					}
				}

				.box__content {

					&__body {
						padding-top: 90px;
					}
				}
			}	
		}
	}
	
	@include desktop {
		
		.box {
			margin-top: 30%;
			padding-bottom: 0;		
		}
	}		

	@include tabletLandscape {

		.box {
			margin-top: 120%;				

			&__title {

				h2 {
					font-size: 45px;
				}

				img {
					width: 25%;
				}
			}

			&__content {
				
				p {
					padding-right: 62px;
					font-size: 20px;
				}

				&__body {
					height: 100vh;
				}
			}

			&.contact-dialog-box {

				.box__title {
					height: 100px;

					&__icon {
						padding-top: 50px;
						padding-right: 40px;
						top: -50px;
						left: -45px;						
					}
				}

				.box__content {

					&__body {
						height: 82vh;						
					}
				}
			}
			
			&.rule-dialog-box {
				margin-bottom: 20px;

				.box__title {
					height: 70px;

					&__icon {
						width: 60%;
						top: 20px;
						left: -25px;					
						padding-top: 14px;
					}

					h2 {
						font-size: 3.906vw;
					}

					#gray-bars {
						margin-right: 14%;
					}
				}

				.box__content {

					&__body {
						ul {

							li {
								font-size: 26px;

								a {
									font-size: 26px;									
								}
							}
						}

						h3 {
							font-size: 3.906vw;
							margin: 50px auto;
						}
					}
				}
			}
			
			&.help-dialog-box {

				.box__content {

					&__body {
						height: 70vh;
					}
				}
			}

			&.playground-dialog-box {
				margin-top: -30%;

				.box__title {
					height: 60px;

					&__icon {
						padding-top: 28px;
						padding-right: 40px;
    					top: -52px;
    					left: -22%;
						height: 83px;
						width: 45%;
						padding-left: 21%;

						h2 {
							font-size: 30px
						}
					}

					#gray-bars {
						margin-left: 38%;
					}

					h3 {
						margin-left: 0;
						font-size: 20px;
						margin-bottom: 0;
					}
				}

				.box__content {

					&__body {
						padding-top: 70px;
					}
				}
			}

			&.history-dialog-box {	
				margin-top: 150px;

				.box__content {

					&__body {
						padding-top: 50px;
					}
				}
			}
		}		
	}
	
	@include mobile {

		.box {
			@include border-radius(0 0 10px 10px);
			margin-top: 69%;
			width: 94%;
			padding-bottom: 0;

			&__title {
				padding: 6px;

				h2 {
					font-size: 11px;
				}

				img {
					width: 14%;
					margin-left: 6px;
				}
			}

			&__content {

				p {
					padding: 16px 10px 15px 15px;
					font-size: 8px;
					letter-spacing: -.1px;
				}

				&__body {
					@include border-radius(10px);
					margin-top: 6px;
					padding: 51px 16px;
					height: 493px;

					&__subtitle {
						background-size: contain;
						height: 28px;
						width: 130px;

						h3 {
							font-size: 11px;
						}
					}

					p {
						padding: 4px 0 4px 4px;
						letter-spacing: -.6px;
						margin-bottom: 2px;
					}
				}
			}

			&.contact-dialog-box {

				.box__title {
					height: 22px;

					&__icon {
						top: 0;
						left: -8px;
						padding: 23px 12px;
						height: 54px;
					}

					h2 {
						font-size: 2.933vw;
						letter-spacing: normal;						
					}
				}

				.box__content {

					&__body {
						margin-top: 0;
						padding-top: 45px;
						height: 63vh;
					}
				}
			}	
			
			&.rule-dialog-box {
				margin-top: 116%;				

				.box__title {
					height: 24px;

					&__icon {
						width: 66%;
						top: 5px;
						left: -19px;
						padding-top: 11px;
						padding-right: 18px;
						
						h2 {
							font-size: 15px;
							letter-spacing: -1px;
						}
					}

					#gray-bars {
						margin-right: 23%;
					}
				}

				.box__content {

					&__body {
						background-image: url('../assets/img/rules-mobile-border.png');
						background-size: 100%;
						margin-top: 5px;
						padding: 85px 3px;

						ul {

							li {
								font-size: 9px;
								margin-bottom: 3px;

								a {
									font-size: 9px;
								}
							}
						}

						h3 {
							font-size: 11px;
							margin: 80px auto 14px;
						}
					}
				}
			}

			&.help-dialog-box {
				width: 93%;
				margin-top: -15%;

				.box__title {
					padding: 5px 20px;
				}

				.box__content {

					&__body {
						height: 55vh;
					}
				}
			}
			
			&.playground-dialog-box {

				.box__title {
					height: 30px;

					&__icon {
						padding-top: 0;
						padding-left: 0;
						top: -8px;
						left: -10px;
						height: 49px;
						width: 50%;
						border-width: 4px;

						h2 {
							font-size: 13px;
							letter-spacing: normal;
						}
					}

					#gray-bars {
						margin-left: 45%;
					}

					h3 {
						margin-left: 0;
						font-size: 18px;

						&.big {
							font-size: 13px;
						}
					}
				}

				.box__content {

					&__body {
						margin-top: 16px;
						padding: 26px 20px 26px 20px;
						min-height: 224px;
					}
				}
			}
			
			&.history-dialog-box {	

				.box__content {

					&__body {
						padding-top: 20px;
						padding-bottom: 20px;
					}
				}
			}			
		}
	}

	@include mobileTiny {
			
		.box {
			
			&__content {
			
				&__body {
					background-size: contain;
				}		
			}

		}	
	}
</style>